<template>
<Page id="tipsligan" title="Tipsligan">
    <Loader v-if="loading && displaymode.mode != 1"></Loader>
    <Alert v-else-if="org_stats.length <= 0" variant="info" >Det finns just nu ingen tipsliga att visa</Alert>
    <div v-else>
        <div class="tips-stats-table">
            <b-row no-gutters class="mb-3">
                <b-col>
                    <label class="font-weight-bold">Filter:</label>
                    <b-select v-model="sort.filter.user">
                        <b-select-option value="0">Alla användare</b-select-option>
                        <b-select-option value="1">Betalande användare</b-select-option>
                        <b-select-option value="2">Ej betalande användare</b-select-option>
                    </b-select>
                </b-col>
                <b-col>
                    <label class="font-weight-bold">Visningsläge:</label>
                    <b-select @change="DisplayModeChange()" v-model="displaymode.mode">
                        <b-select-option value="0">Senaste</b-select-option>
                        <b-select-option value="1">Match för match</b-select-option>
                    </b-select>
                </b-col>
                <b-col>
                    <label class="font-weight-bold">Följ:</label>
                    <b-select v-model="displaymode.follow">
                        <b-select-option :value="user.id" v-for="user in users" :key="user.id">{{user.username}}</b-select-option>
                    </b-select>
                </b-col>
            </b-row>
            <b-row no-gutters class="justify-content-center align-items-end" v-if="displaymode.mode == 1">
                <b-col cols="12" lg="2" class="mb-5 mb-lg-0">
                    <b-button @click="Play()" v-if="!displaymode.slider.isPlaying" variant="primary">
                        <b-row>
                            <b-col cols="auto">
                                <inline-svg :src="this.$images.svg('common/play.svg')" width="24" height="24" />
                            </b-col>
                            <b-col>Spela</b-col>
                        </b-row>
                    </b-button>
                    <b-button @click="Pause()" v-else variant="secondary">
                        <b-row>
                            <b-col>
                                <inline-svg :src="this.$images.svg('common/pause.svg')" width="24" height="24" />
                            </b-col>
                            <b-col>Pausa</b-col>
                        </b-row>
                    </b-button>
                </b-col>
                <b-col cols="1" class="d-block d-lg-none">&nbsp;</b-col>
                <b-col cols="10">
                    <vue-slider @change="SliderUpdate" :min="0" tooltip="always" :max="totalgames" :lazy="true" :drag-on-click="true" v-model="displaymode.slider.gamenr"></vue-slider>
                </b-col>
                <b-col cols="1" class="d-block d-lg-none">&nbsp;</b-col>
            </b-row>
            <b-row class="tips-stats-table-header sticky-top bg-white" :class="scrolled ? 'sticky-top-shadow' : ''">
                <b-col cols="4">
                    <b-row no-gutters class="align-items-center">
                        <b-col cols="2" class="text-center">
                            <span>#</span>
                        </b-col>
                        <b-col cols="10" class="sortable text-left" :class="SortableClass('username')" @click="SortBy('username', true)">
                            <span class="d-none d-sm-inline">Användare</span>
                            <span class="d-inline d-sm-none">Anv.</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col @click="SortBy('resultpoints', false, true)" class="sortable" :class="SortableClass('resultpoints')">
                    <span class="d-none d-xl-inline">Resultat</span>
                    <span class="d-inline d-xl-none">R</span>
                </b-col>
                <b-col class="sortable" @click="SortBy('betpoints', false, true)" :class="SortableClass('betpoints')">
                    <span class="d-none d-xl-inline">1-X-2</span>
                    <span class="d-inline d-xl-none">1X2</span>
                </b-col>
                <b-col class="sortable" @click="SortBy('bonuspoints', false, true)" :class="SortableClass('bonuspoints')">
                    <span class="d-none d-xl-inline">Bonus</span>
                    <span class="d-inline d-xl-none">B</span>
                </b-col>
                <b-col class="sortable" @click="SortBy('questionpoints', false, true)" :class="SortableClass('questionpoints')">
                    <span class="d-none d-xl-inline">Frågor</span>
                    <span class="d-inline d-xl-none">F</span>
                </b-col>
                <b-col class="sortable" @click="SortBy('gamesnitt', false, true)" :class="SortableClass('gamesnitt')">
                    <span class="d-none d-xl-inline">Poäng/Match</span>
                    <span class="d-inline d-xl-none">P/M</span>
                </b-col>
                <b-col class="sortable" @click="SortBy('totalpoints', false, true)" :class="SortableClass('totalpoints')">
                    <span class="d-none d-xl-inline">Totalt</span>
                    <span class="d-inline d-xl-none">TP</span>
                </b-col>
            </b-row>
            <Alert v-if="stats.length <= 0" variant="info" >Inga användare matchade filtret</Alert>
            <transition-group class="list-group" name="tips-list" tag="div">
                <b-row v-for="urow in stats" :id="'tips_stats_row_' + urow.userid" :key="urow.userid" class="tips-stats-row align-items-center"  :class="TipsRowClasses(urow)">
                    <b-col cols="4">
                        <b-row no-gutters class="align-items-center">
                            <b-col cols="2" class="text-center">
                                {{ urow.placement }}
                            </b-col>
                            <b-col cols="10"  class="groups-team" :id="'tipsligan_user_row_' + urow.userid" >
                                <b-img rounded="circle" class="tips-user-image" :src="avatar(urow)" />{{ urow.username }}
                                <UserPopover :target="'tipsligan_user_row_' + urow.userid" :user="urow"></UserPopover>
                            </b-col>
                        </b-row>
                    </b-col>

                    <b-col class="points">
                        {{ urow.resultpoints }}
                    </b-col>
                    <b-col class="points">
                        {{ urow.betpoints }}
                    </b-col>
                    <b-col class="points">
                        {{ urow.bonuspoints }}
                    </b-col>
                    <b-col class="points">
                        {{ urow.questionpoints }}
                    </b-col>
                    <b-col class="points">
                        {{ round(urow.gamesnitt,2) }}
                    </b-col>
                    <b-col class="points total-points">
                        {{ urow.totalpoints }}
                    </b-col>
                </b-row>
            </transition-group>           
        </div>
    </div>
</Page>
</template>

<script>

const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");
const Loader = () => import("@/components/miscellaneous/Loader");

export default {
    name: "Tipsligan",
    components: {
        Alert,
        Page,
        Loader,
    },
    data() {
        return {
            scrolled: false,
            sort: {
                currentSort: {
                    key: "totalpoints",
                    desc: true,
                    float: true
                },
                orgSort: [{
                        key: "totalpoints",
                        desc: true,
                        float: true
                    },
                    {
                        key: "bonuspoints",
                        desc: true,
                        float: true
                    },
                    {
                        key: "betpoints",
                        desc: true,
                        float: true
                    },
                    {
                        key: "resultpoints",
                        desc: true,
                        float: true
                    },
                    {
                        key: "usernamne",
                        desc: false,
                        float: false
                    },
                ],
                filter: {
                    user: "1", // 0=alla, 1 betalande, 2 ej betalande
                    group: false, // om false, ingen egen grupp, annars id för användarens grupper
                }
            },
            displaymode: {
                mode: 0, // 0 = hela, 1 gamenr
                slider: {
                    gamenr: 0,
                    isPlaying: false,
                    timer: false
                },
                follow: false
            }
        };
    },
    metaInfo: {
        title: "Tipsligan",
        meta: [{
            name: "description",
            content: "Dessa tipsare hämtar hem vinster i turneringen!",
        }, ],
    },
    computed: {
        loading() {
            return this.$store.getters["stats/loading"];
        },
        usersaccess() {
            var data = this.$store.getters["stats/stats"];
            if (data)
                return data.useraccess;
            return {};
        },
        totalgames() {
            var data = this.$store.getters["stats/stats"];
            if (data) {
                return parseInt(data.stats[0].totalgames);
            }
            return 0;
        },
        org_stats(){
            var data = this.$store.getters["stats/stats"];
            if (data)
                return data;
            return [];
        },
        stats() {           
            var sort = JSON.parse(JSON.stringify(this.sort.orgSort));
            sort.unshift(this.sort.currentSort);
            // filtrera 
            var data = this.org_stats;
            var filtered = data.stats.filter((row) => {
                var ua = this.userAccess(row.userid);
                if (ua) {
                    switch (this.sort.filter.user) {
                        case "0":
                            return true;
                        case "1":
                            return ua >= 20;
                        case "2":
                            return ua < 20;
                    }
                }
                return false;
            });
            // sortera
            var stats = this.$functions.sortm(filtered, sort);
            // placera
            var placements = this.$functions.fixPlacements(
                stats,
                this.sort.currentSort.key,
                "placement"
            );

            if (this.sort.filter.group) // om egen grupp
            {
                // filtera ut på userid och sen fixplacements igen med egen nyckel
            }

            return placements;            
        },
        gamenr() {
            var data = this.$store.getters["stats/stats"];
            if (data) return data.gamenr;
            return [];
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        users(){
            var stats = this.stats;
            var users = [];
            for(var i = 0; i < stats.length; i++)
            {
                var u = {
                    id: stats[i].userid,
                    username: stats[i].username
                };
                users.push(u);
            }
            return users;
        }
    },
    methods: {       
        avatar(row) {
            return this.$images.userAvatar(row.userid);
        },
        round(number, decimals) {
            return this.$formatter.round(number, decimals);
        },
        userAccess(uid) {
            var allaccess = this.usersaccess;
            if (allaccess[uid])
                return allaccess[uid];
            return false;
        },
        SortableClass(key) {
            if (this.sort.currentSort.key == key) {
                var cname = "current-sort";
                if (this.sort.currentSort.desc)
                    cname += " sort-desc";
                else cname += " sort-asc";
                return cname;
            }
            return "";
        },
        SortBy(key, asc, float) {
            if (this.sort.currentSort.key == key) {
                this.sort.currentSort.desc = !this.sort.currentSort.desc; // om samma key vänd bara på ordningen 
            } else {
                // ny så börja alltid med desc om inte asc
                var desc = true;
                if (asc)
                    desc = false;
                this.sort.currentSort = {
                    key: key,
                    desc: desc,
                    float: float
                };
            }
        },
        TipsRowClasses(row)
        {
            if(this.currentUser.id == row.userid)
                return "current-user-row";
            if(this.displaymode.follow == row.userid)
                return "current-follow-row";
            return "";
        },
        handleScroll() {
            this.scrolled = window.scrollY > 0;
        },
        SliderUpdate() {
            this.getStats();
        },
        DisplayModeChange() {
            this.getStats();
        },
        getStats() {
            var gnr = "";
            if (this.displaymode.mode == 1) {
                gnr = this.displaymode.slider.gamenr;
            }
            this.$store.dispatch("stats/getStats", gnr);
        },
        NextGame() {
            this.displaymode.slider.gamenr++;
            this.getStats();
            if (this.displaymode.slider.gamenr < this.totalgames)
                this.displaymode.slider.timer = setTimeout(this.NextGame, 2000);
            else {
                this.Pause();
            }
        },
        Play() {
            if (this.displaymode.slider.gamenr >= this.totalgames)
                this.displaymode.slider.gamenr = 0;
            this.displaymode.slider.isPlaying = true;
            this.NextGame();
        },
        Pause() {
            this.displaymode.slider.isPlaying = false;
            clearTimeout(this.displaymode.slider.timer);
        },
        FollowUser() {                      
            var userrow = document.getElementById("tips_stats_row_" + this.displaymode.follow);
            if (userrow) {             
                this.$functions.scroll(userrow, {center:true});
            }    
        },
    },
    watch: {
        stats() {
            setTimeout(
            this.FollowUser, 250);
        }
    },
    mounted() {
        this.getStats();
        this.displaymode.follow = this.currentUser.id;
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
